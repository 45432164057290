<template>
  <div class="container">
    <a-layout>
      <a-layout
        id="components-layout-demo-custom-trigger"
        style="position: relative"
      >
        <Header />
        <a-layout class="section">
          <Sider />
          <a-layout-content
            :style="{
              margin: '20px 20px',
              marginTop: '80px',
              minHeight: '280px',
            }"
          >
            <!--  padding: '24px',background: '#fff', -->
            <router-view></router-view>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-layout>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Sider from "@/components/Sider.vue";
export default {
  name: "Car",
  data() {
    return {};
  },
  components: {
    Header,
    Sider,
  },
  // computed: {
  //   theme() {
  //     return this.$store.state.theme;
  //   },
  // },
  watch:{
    // theme: {
    //   handler(val, val1) {
    //     if (val != val1) {
    //       document.title = this.theme.chinese_name;
    //     }
    //   },
    //   immediate: true,
    // },
  },
  mounted() {
    // document.title = this.theme.chinese_name;
  },
};
</script>
<style>
#components-layout-demo-custom-trigger {
  height: 100vh;
}
.section {
  height: 100%;
}
.ant-layout-content::-webkit-scrollbar {
  width: 0;
}
</style>