var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-header',[_c('a-page-header',[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/logo.png")}})]),_c('template',{slot:"extra"},[_c('a-dropdown',{attrs:{"placement":"bottomLeft"}},[_c('a',{staticClass:"ant-dropdown-link",on:{"click":function (e) { return e.preventDefault(); }}},[_c('img',{attrs:{"src":require("../assets/user.png"),"alt":""}}),_c('p',[_vm._v(" admin ")]),_c('a-icon',{attrs:{"type":"down"}})],1),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',{key:"2",on:{"click":_vm.logout}},[_vm._v(" 退出 ")])],1)],1)],1)],2),_c('a-modal',{attrs:{"title":"修改密码","confirm-loading":_vm.confirmLoading,"ok-text":"确认","cancel-text":"取消"},on:{"ok":_vm.changePsw},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"当前密码："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'old_password',
            {
              rules: [{ required: true, message: '请输入当前密码!' }],
            } ]),expression:"[\n            'old_password',\n            {\n              rules: [{ required: true, message: '请输入当前密码!' }],\n            },\n          ]"}],attrs:{"type":"password"}})],1),_c('a-form-item',{attrs:{"label":"新密码："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'new_password',
            {
              rules: [{ required: true, message: '请再次输入新密码!' }],
            } ]),expression:"[\n            'new_password',\n            {\n              rules: [{ required: true, message: '请再次输入新密码!' }],\n            },\n          ]"}],attrs:{"type":"password"}})],1),_c('a-form-item',{attrs:{"label":"确认密码："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'new_password1',
            {
              rules: [{ required: true, message: '请再次输入新密码!' }],
            } ]),expression:"[\n            'new_password1',\n            {\n              rules: [{ required: true, message: '请再次输入新密码!' }],\n            },\n          ]"}],attrs:{"type":"password"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }