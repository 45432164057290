<template>
  <!-- <div> -->
  <!-- <div
      :class="{ menu_app_bg: !menu_app && slider_app }"
      @click="menu_app = !menu_app"
    ></div> -->
  <a-layout-sider :class="{ 'ant-layout-sider-show': menuShow }" ref="sider">
    <a-menu
      theme="dark"
      :open-keys="openKeys"
      :selectedKeys="selectedKeys"
      mode="inline"
      @openChange="onOpenChange"
      @select="select"
    >
      <!-- <a-sub-menu v-for="item in siderList" :key="item.page_path">
        <span slot="title">
           <a-icon type="area-chart" v-if="item.page_path=='/statistics'"/>
          <a-icon type="file-text" v-if="item.page_path=='/order'"/>
          <a-icon type="hdd"  v-if="item.page_path=='/device'"/>
          <a-icon type="android"   v-if="item.page_path=='/appVersion'"/>
          <a-icon type="bug" v-if="item.page_path=='/log'"/>
          <a-icon type="team" v-if="item.page_path=='/account'"/> -->
      <!-- <img src="../assets/icon1.png" alt="" v-if="item.page_path=='/statistics'"/>
          <img src="../assets/icon2.png" alt="" v-if="item.page_path=='/order'"/>
          <img src="../assets/icon3.png" alt="" v-if="item.page_path=='/device'"/>
          <img src="../assets/icon4.png" alt="" v-if="item.page_path=='/appVersion'"/>
          <img src="../assets/icon5.png" alt="" v-if="item.page_path=='/blog'"/>
          <img src="../assets/icon6.png" alt="" v-if="item.page_path=='/account'"/>
          <img src="../assets/icon8.png" alt="" v-if="item.page_path=='/oem'"/> --
          <span>{{item.name}}</span>
        </span>
        <a-menu-item :key="item1.page_path" v-for="item1 in item.children" style="padding-left:52px">
          <router-link :to="item1.page_path">
            {{item1.name}}</router-link>
        </a-menu-item>
      </a-sub-menu> -->
      <a-menu-item key="/goods">
        <span>
          <router-link to="/goods"> <a-icon type="shopping" />商品管理</router-link>
        </span>
      </a-menu-item>
      <a-menu-item key="/indexRecommend">
        <span>
          <router-link to="/indexRecommend"> <a-icon type="mobile" />首页推荐管理</router-link>
        </span>
      </a-menu-item>
      <a-menu-item key="/rank">
        <span>
          <router-link to="/rank"> <a-icon type="control" />排行榜管理</router-link>
        </span>
      </a-menu-item>
      <a-menu-item key="/search">
        <span>
          <router-link to="/search"> <a-icon type="search" />搜索管理</router-link>
        </span>
      </a-menu-item>
      <a-menu-item key="/wxGroup">
        <span>
          <router-link to="/wxGroup"> <a-icon type="wechat" />微信群管理</router-link>
        </span>
      </a-menu-item>
      <a-menu-item key="/user">
        <span>
          <router-link to="/user"> <a-icon type="user" />用户管理</router-link>
        </span>
      </a-menu-item>
      <a-menu-item key="/setting">
        <span>
          <router-link to="/setting"> <a-icon type="setting" />系统设置</router-link>
        </span>
      </a-menu-item>
      <!-- <a-menu-item key="/api" v-if="api">
        <! <img src="../assets/icon7.png" alt="" /> --
        <span
          ><a
            style="display: inline"
            :href="api.page_path"
            target="_blank"
            >API对接</a
          ></span
        >
      </a-menu-item> -->
    </a-menu>
    <div
      :class="{ menu: 1, menu_1: !arrow, menu_0: arrow }"
      @click="toggleCollapsed"
    >
      <img src="../assets/arrow1.png" alt="" v-if="!arrow" />
      <img src="../assets/arrow2.png" alt="" v-else />
    </div>
  </a-layout-sider>
  <!-- </div> -->
</template>
<script>
export default {
  data() {
    return {
      rootSubmenuKeys: [
        "/statistics",
        "/device",
        "/order",
        "/blog",
        "/account",
        "/appVersion",
        "/oem",
      ],
      openKeys: [],
      selectedKeys: [],
      collapsed: false,
      //user_role: window.localStorage.getItem("user_role"),
      //siderList: window.localStorage.getItem("auth"),
      logotxt: true,
      menuShow: true,
      arrow: false,
      api: false,
    };
  },
  // watch:{
  //   '$route':function(to,from){
  //     console.log(to,from)
  //   }
  // },
  created() {
    // this.siderList = JSON.parse(window.localStorage.getItem("auths"));
    // this.siderList = JSON.parse(window.localStorage.getItem("auth"));
    // var siderList = this.siderList;
    // if (siderList[siderList.length - 1].name == "API对接") {
    //   this.api = siderList[siderList.length - 1];
    //   this.siderList.pop();
    //   console.log(this.siderList);
    // }
  },
  mounted() {
    this.windowResize();
    var router = this.$route.path.split("/");
    this.openKeys = ["/" + router[1]];
    this.selectedKeys = ["/" + router[1]];
    window.addEventListener("resize", () => this.windowResize(), false);
  },
  methods: {
    windowResize() {
      // 通过捕获系统的onresize事件触发我们需要执行的事件
      this.clientW =
        document.documentElement.clientWidth || document.body.clientWidth;
      this.clientChange();
    },
    clientChange() {
      if (this.clientW > 1086) {
        this.menuShow = false;
        this.arrow = false;
      } else {
        this.menuShow = false;
        this.arrow = true;
      }
    },
    toggleCollapsed() {
      this.menuShow = !this.menuShow;
      this.arrow = !this.arrow;
    },
    select({ key }) {
      console.log(key);
      this.selectedKeys = [key];
      this.$router.push(key);
    },
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
  },
};
</script>
<style scoped>
.ant-layout-sider {
  height: 100vh;
  padding-top:60px;
  position: relative;
  background: transparent;
}
.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-show,/deep/.ant-menu-dark .ant-menu-inline.ant-menu-sub{
  background:transparent;
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub{
  background:#31324b;
}
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title{
  background:#31324b;
}
/deep/.ant-menu-vertical .ant-menu-item, /deep/.ant-menu-vertical-left .ant-menu-item, /deep/.ant-menu-vertical-right .ant-menu-item, /deep/.ant-menu-inline .ant-menu-item, /deep/.ant-menu-vertical .ant-menu-submenu-title, /deep/.ant-menu-vertical-left .ant-menu-submenu-title, /deep/.ant-menu-vertical-right .ant-menu-submenu-title, /deep/.ant-menu-inline .ant-menu-submenu-title{
  margin:0
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, /deep/.ant-menu-vertical-right > .ant-menu-item, /deep/.ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, /deep/.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, /deep/.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, /deep/.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{
  height:36px
}
/deep/.ant-menu-dark .ant-menu-inline.ant-menu-sub{
  box-shadow: unset;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected{
  background:rgba(232,233,255,.1);
  margin:0
}
.ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item-active, .ant-menu-dark .ant-menu-submenu-active, .ant-menu-dark .ant-menu-submenu-open, .ant-menu-dark .ant-menu-submenu-selected, .ant-menu-dark .ant-menu-submenu-title:hover,.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a{
  color: rgba(255,255,255,.5);
}
/deep/.ant-menu-dark .ant-menu-item a{
  color: rgba(255,255,255,1)!important;
}
/deep/.ant-menu-item-selected > a::before{
  border-left:6px solid  #FFC714;;
}
.ant-layout-sider-children>.ant-menu>li{
  margin-top:10px!important
}
/deep/.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow{
  opacity: .5!important;
}
/deep/.ant-menu-dark .ant-menu-item:hover, /deep/.ant-menu-dark .ant-menu-item-active, /deep/.ant-menu-dark .ant-menu-submenu-active, /deep/.ant-menu-dark .ant-menu-submenu-open, /deep/.ant-menu-dark .ant-menu-submenu-selected, /deep/.ant-menu-dark .ant-menu-submenu-title:hover{
  color: rgba(255,255,255,.5)!important;
}
.ant-menu-dark, .ant-menu-dark .ant-menu-sub,
/deep/.ant-layout-sider-children>.ant-menu-dark>.ant-menu-item a{
  color: rgba(255,255,255,.5)!important;
}
.ant-menu-item .anticon, .ant-menu-submenu-title .anticon{
  color:#fff
}
.menu {
  position: absolute;
  left: 100%;
  top:50%;
  margin-top:17px;
  z-index: 10;
  padding: 0;
  border: 0;
}
.menu img{
  width:16px
}
.menu .anticon {
  font-size: 24px;
  margin-top: 20px;
  margin-left: 20px;
}
.ant-menu-submenu-title span,.ant-layout-sider-children>.ant-menu>.ant-menu-item{
  display: flex;
  align-items: center;
}
.ant-menu-submenu-title img,.ant-menu-item img{
  width:16px;
  height: 16px;
  margin-right: 12px;
}
/deep/.ant-menu-dark .ant-menu-item a {
  color: rgba(255, 255, 255, 0.65);
}
/deep/.ant-menu-dark .ant-menu-item:hover a {
  color: #fff;
  background-color: transparent;
}
.logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 64px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}
/deep/.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.ant-menu {
  flex: 1;
  overflow-y: scroll;
}
.ant-menu::-webkit-scrollbar {
  width: 0;
}

.ant-layout-sider-show {
  width: 0 !important;
  flex: 0 !important;
  min-width: 0 !important;
  max-width: 0 !important;
}
@media screen and (max-width: 1086px) {
  .ant-layout-sider {
    width: 200px !important;
    flex: 0 0 200px !important;
    min-width: 200px !important;
    max-width: 200px !important;
    position: absolute;
    left: -200px;
  }
  .ant-layout-sider-show {
    position: absolute;
    left: 0;
    z-index: 2;
  }
  .menu_1{
    width:calc(100vw - 200px);
    height: calc(100vh - 60px);
    margin-top:0;
    display: flex;
    top:60px;
    justify-content: flex-start;
    align-items: center;
  }
  .menu_0{
    width:auto;
    height: auto;
    position: absolute;
  left: 100%;
  top:50%;
  margin-top:17px;
  z-index: 10;
  padding: 0;
  border: 0;
  }
}
@media screen and (max-width: 768px) {
  .ant-layout-sider {
    position: absolute;
    left: -200px;
  }
  .ant-layout-sider-show {
    position: absolute;
    left: 0;
    z-index: 2;
  }
}
</style>