<template>
  <a-layout-header>
    <!-- <a-icon
          class="trigger"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)"
        /> -->
    <a-page-header>
      <div class="logo">
        <img src="../assets/logo.png"/>
        <!-- <img :src="theme.logo_img"/> -->
        <!-- 蜜葵管理后台 -->
      </div>
      <template slot="extra">
        <a-dropdown placement="bottomLeft">
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            <img src="../assets/user.png" alt="" />
            <p> admin
              <!--{{ nickName }} <span>APPID:{{appId}}</span> -->
            </p>
            <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <!-- <a-menu-item key="1" @click="openPsw"> 修改密码 </a-menu-item> -->
            <a-menu-item key="2" @click="logout"> 退出 </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
    </a-page-header>

    <a-modal
      v-model="visible"
      title="修改密码"
      :confirm-loading="confirmLoading"
      @ok="changePsw"
      ok-text="确认"
      cancel-text="取消"
    >
      <a-form :form="form">
        <a-form-item label="当前密码：">
          <a-input
            type="password"
            v-decorator="[
              'old_password',
              {
                rules: [{ required: true, message: '请输入当前密码!' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="新密码：">
          <a-input
            type="password"
            v-decorator="[
              'new_password',
              {
                rules: [{ required: true, message: '请再次输入新密码!' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="确认密码：">
          <a-input
            type="password"
            v-decorator="[
              'new_password1',
              {
                rules: [{ required: true, message: '请再次输入新密码!' }],
              },
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </a-layout-header>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      form: this.$form.createForm(this, { name: "form" }),
      nickName: window.localStorage.getItem("nickName"),
      appId: window.localStorage.getItem("appId"),
      visible: false,
      confirmLoading: false,
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    theme() {
      return this.$store.state.theme;
    },
  },
  activated() {
    this.nickName = window.localStorage.getItem("nickName");
  },
  methods: {
    logout() {
      var that = this;
      this.$confirm({
        title: "提示",
        content: "是否确定退出登录？",
        okText: "确定",
        okType: "warning",
        cancelText: "取消",
        onOk() {
          // var data = {};
          that.$logOut();
          // that.axios.get("/AdminApi/auth/logout").then((res) => {
          //   if (that.$code(res)) {
          //     window.sessionStorage.setItem("Authorization", "");
          //     window.sessionStorage.setItem("auths", "");
          //     window.sessionStorage.setItem("nickName", "");
          //     window.sessionStorage.setItem("type", "");
          //     that.axios.defaults.headers.post["Authorization"] = "";
          //     that.$router.push("/login");
          //   }
          // });
        },
        onCancel() {},
        class: "test",
      });
    },
    openPsw() {
      this.visible = true;
      this.confirmLoading = false;
    },
    changePsw() {
      var that = this;
      this.confirmLoading = true;
      that.form.validateFields((err, values) => {
        if (!err) {
          if (values.new_password == values.new_password1) {
            var data = {
              old_password: values.old_password,
              new_password: values.new_password,
            };
            that.axios
              .post("/AdminApi/auth/modifyPass", data)
              .then(function (res) {
                if (that.$code(res)) {
                  that.visible = false;
                }
                that.confirmLoading = false;
              });
          } else {
            that.$message.error("两次密码不一致");
            that.confirmLoading = false;
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.logo {
  position: absolute;
  left: 0;
  top: 0;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
}
.logo img {
  height: 44px;
  margin-right: 8px;
}
.ant-dropdown-link,
.ant-layout-header,
.ant-page-header,
.ant-page-header-heading {
  height: 60px;
}
.ant-layout-header {
  background: rgb(255, 255, 255);
  position: absolute;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
}
.ant-page-header-ghost {
  padding: 0;
}
.ant-page-header-heading-extra img{
  width: 36px;
  height: 36px;
  margin:2px 10px 2px 2px;
      border-radius: 50%;
}
.ant-dropdown-link {
  width: 166px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  margin-top: 10px;
  margin-right: 20px;
  color: #333;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.ant-dropdown-link p{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;font-size: 12px;
  line-height: 17px;
margin:0
}
.ant-dropdown-link p span{
  font-size: 10px;
  line-height: 14px;
  color:#979797
}
.anticon-down{
  position: absolute;
  right:16px
}
.ant-dropdown ul{
  overflow: hidden;
}
.ant-input{
  width:100%
}
@media screen and (max-width: 990px) {
  .ant-page-header-heading-extra {
    display: flex !important;
    justify-content: flex-end !important;
    padding-top: 0 !important;
    width: auto;
  }
}
@media  screen and (max-width: 576px){
.ant-dropdown-link{
  margin-top:12px;
  float: right;
}
}
</style>